const initialState = {
  navColor: 'white',
  navDefaultColor: 'white',
  navTrigger: null,
  showNav: true,
  currentPage: null
}

export default function utilReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_NAV_COLOR":
      return {
        ...state,
        navColor: action.payload
      }
    case "SET_NAV_DEFAULT_COLOR":
      return {
        ...state,
        navDefaultColor: action.payload
      }
    case "SET_NAV_TRIGGER":
      return {
        ...state,
        navTrigger: action.payload
      }
    case "SET_SHOW_NAV":
      return {
        ...state,
        showNav: action.payload
      }
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload
      }
    default: return state
  }
}